<template>
  <v-container>

analytics


  </v-container>
</template>

<script>

export default {
  name: 'AnalyticsComponent',
}
</script>
