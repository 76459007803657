<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">

        <AnalyticsComponent/>

    </transition>
  </div>
</template>

<script>

import AnalyticsComponent from '@/components/AnalyticsComponent.vue'

export default {
  name: 'Analytics',
  components: {
    AnalyticsComponent
  }
}
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style scoped lang="scss">
</style>
